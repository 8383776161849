import CommunityConnectActionLocation from '../enums/CommunityConnectActionLocation';
import CommunityConnectRelationshipAction from '../enums/CommunityConnectRelationshipAction';
import { getAmplitudeInstance } from '../amplitudeHelpers';

type logCommunityConnectActionSelectedParams = {
  carousel_position?: number;
  extra_reasons?: string[];
  item_location: CommunityConnectActionLocation;
  item_position?: number;
  primary_reason_label?: string;
  primary_reason_score?: number;
  primary_reason_type?: string;
  relationship_action: CommunityConnectRelationshipAction;
  source_user_id?: string;
  target_user_id?: string;
};

/**
 * User selected an option to change connect relationship with another user
 * @param params - Attributes to log with the amplitude event
 * @param params.action - (Deprecated. Use relationship_action instead) The connect action being applied
 * @param params.carousel_position - The 0-indexed position of the carousel in the user's feed, if the interaction occurred on the carousel.
 * @param params.extra_reasons - (Suggested members) List of JSON strings with metadata containing other reasons for the recommendation
 * @param params.item_location - The location of the interaction.
 * @param params.item_position - The 0-indexed position of the interaction in the relevant list.
 * @param params.primary_reason_label - (Suggested members) Free-text message explaining why the user is recommended.
 * @param params.primary_reason_score - (Suggested members) Recommendation score for the primary reason
 * @param params.primary_reason_type - (Suggested members and contacts) The reason the user is recommended or shown
 * @param params.relationship_action - The connect action being applied. These actions match the action in the rel urn's from belay service, e.g. request-follow for urn:alltrails:connect:request-follow.
 * @param params.source_user_id - User initiating the connect relationship change
 * @param params.target_user_id - User the connect relationship change applies to
 */
const logCommunityConnectActionSelected = (
  {
    carousel_position,
    extra_reasons,
    item_location,
    item_position,
    primary_reason_label,
    primary_reason_score,
    primary_reason_type,
    relationship_action,
    source_user_id,
    target_user_id
  }: logCommunityConnectActionSelectedParams,
  callback?: () => void
) => {
  getAmplitudeInstance().logEvent(
    'community connect action selected',
    {
      'carousel position': carousel_position,
      'extra reasons': extra_reasons,
      'item location': item_location,
      'item position': item_position,
      'primary reason label': primary_reason_label,
      'primary reason score': primary_reason_score,
      'primary reason type': primary_reason_type,
      'relationship action': relationship_action,
      'source user id': source_user_id,
      'target user id': target_user_id
    },
    callback
  );
};

export default logCommunityConnectActionSelected;
