/**
 * Type of page the user was on when the prompt was triggered: home, park, trail, signup wall, etc.
 */
enum InstallPromptLocation {
  HomePage = 'home page',
  LocationPage = 'location page',
  ParksPage = 'parks page',
  PoiPage = 'poi page',
  SignupPage = 'signup page',
  TrailPage = 'trail page'
}

export default InstallPromptLocation;
