/**
 * Types of content that can be reported.
 */
enum ReportContentType {
  Activity = 'activity',
  Comment = 'comment',
  List = 'list',
  Map = 'map',
  Photo = 'photo',
  ProfilePhoto = 'profile photo',
  Review = 'review',
  Trail = 'trail',
  User = 'user'
}

export default ReportContentType;
